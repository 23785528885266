import { useState } from "react";

import { sendAadhaarOtp, verifyAadhaarOtp } from "apis/serverRoutes";
import Button, { ButtonType } from "components/molecules/Button";
import Input from "components/molecules/Input";
import { DEFAULT_ERROR } from "utils/constants";

import styles from "./index.module.scss";
import { NidDetails } from "../types";
import PhotoUploader from "../PhotoUploader";
import { CompletedIcon } from "../icons";

interface NidProps {
  nidDetails: NidDetails;
  setNidDetails: (val: NidDetails) => void;
}

interface AadhaarOtpData {
  aadhaar: string;
  referenceId: string;
  status: number;
  message: string;
}

const Nid = ({ setNidDetails, nidDetails }: NidProps) => {
  const [aadhaarOtpData, setAadhaarOtpData] = useState<AadhaarOtpData | null>(
    null,
  );
  const [aadhaarNo, setAadhaarNo] = useState<string>("");
  const [aadhaarOtp, setAadhaarOtp] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [aadhaarError, setAadhaarStatus] = useState<string>("");

  const onAadhaarSendOtpClick = () => {
    if (!aadhaarNo) return;
    setLoader(true);
    sendAadhaarOtp(aadhaarNo)
      .then((res: any) => {
        if (res.data) {
          setAadhaarOtpData(res.data);
          setAadhaarStatus("OTP sent to registered mobile");
        } else setAadhaarStatus(res?.response?.data?.message || DEFAULT_ERROR);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoader(false));
  };

  const onAadhaarVerifyOtpClick = () => {
    if (!aadhaarOtp || !aadhaarOtpData) return;
    setLoader(true);
    verifyAadhaarOtp({
      otp: aadhaarOtp,
      aadharNo: aadhaarOtpData.aadhaar,
      referenceId: aadhaarOtpData.referenceId,
    })
      .then((res: any) => {
        if (res.data) setNidDetails({ ...nidDetails, aadharNo: aadhaarNo });
        else setAadhaarStatus(res?.response?.data?.message || DEFAULT_ERROR);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoader(false));
  };

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["uploader-holder"]}>
          <div className={styles["label"]}>Upload Photo</div>
          <PhotoUploader
            className={styles["uploader"]}
            image={nidDetails.photo}
            onImageChange={(photo) => setNidDetails({ ...nidDetails, photo })}
          />
        </div>
        <div className={styles["uploader-holder"]}>
          <div className={styles["label"]}>Upload Aadhaar</div>
          <PhotoUploader
            className={styles["uploader"]}
            image={nidDetails.aadhar}
            onImageChange={(aadhar) => setNidDetails({ ...nidDetails, aadhar })}
          />
        </div>
      </div>
      <div className={styles["container"]}>
        <div className={styles["uploader-holder"]}>
          <div className={styles["label"]}>Upload PAN</div>
          <PhotoUploader
            className={styles["uploader"]}
            image={nidDetails.pan}
            onImageChange={(pan) => setNidDetails({ ...nidDetails, pan })}
          />
        </div>
        <div className={styles["uploader-holder"]}>
          <div className={styles["label"]}>Upload Passport</div>
          <PhotoUploader
            className={styles["uploader"]}
            image={nidDetails.passport}
            onImageChange={(passport) =>
              setNidDetails({ ...nidDetails, passport })
            }
          />
        </div>
      </div>
      <div className={styles["input-container"]}>
        <div className={styles["input"]}>
          <Input
            label={"Aadhaar Number"}
            value={nidDetails.aadharNo || aadhaarNo}
            onChange={(ev) => {
              setAadhaarStatus("");
              setAadhaarNo(ev.target.value);
            }}
            placeholder={"Enter Aadhaar number"}
            disabled={!!aadhaarOtpData}
          />
          {!nidDetails.aadharNo && (
            <>
              {aadhaarOtpData ? (
                <>
                  <Input
                    label={"Enter OTP"}
                    value={aadhaarOtp}
                    onChange={(ev) => setAadhaarOtp(ev.target.value)}
                    placeholder={"Enter Aadhaar OTP"}
                    className={styles["otp-input"]}
                  />
                  <div className={styles["otp-cta-holder"]}>
                    <Button
                      disabled={!aadhaarOtp}
                      onClick={onAadhaarVerifyOtpClick}
                      loading={loader}
                      className={styles["otp-cta"]}
                    >
                      Verify
                    </Button>
                    <Button
                      onClick={() => {
                        setAadhaarOtpData(null);
                        setAadhaarStatus("");
                      }}
                      className={styles["reset-cta"]}
                      buttonType={ButtonType.Outlined}
                    >
                      Edit
                    </Button>
                  </div>
                </>
              ) : (
                <div className={styles["otp-cta-holder"]}>
                  <Button
                    disabled={!aadhaarNo}
                    onClick={onAadhaarSendOtpClick}
                    loading={loader}
                    className={styles["otp-cta"]}
                  >
                    Send OTP
                  </Button>
                </div>
              )}
            </>
          )}
          {!!nidDetails.aadharNo ? (
            <div className={styles["verified-text"]}>
              <CompletedIcon className={styles["completed-icon"]} />
              Verified
            </div>
          ) : (
            !!aadhaarError && (
              <div className={styles["error-text"]}>{aadhaarError}</div>
            )
          )}
        </div>
        <Input
          className={styles["input"]}
          label={"PAN Number"}
          value={nidDetails.panNo}
          onChange={(ev) =>
            setNidDetails({
              ...nidDetails,
              panNo: ev.target.value?.toUpperCase(),
            })
          }
          placeholder={"Enter PAN number"}
        />
        <Input
          className={styles["input"]}
          label={"Passport Number"}
          value={nidDetails.passportNo}
          onChange={(ev) =>
            setNidDetails({ ...nidDetails, passportNo: ev.target.value })
          }
          placeholder={"Enter Passport number"}
        />
      </div>
    </>
  );
};

export default Nid;
