import {
  AddressDetails,
  BasicDetailsData,
  EducationDetails,
  EmployerDetails,
  NidDetails,
} from "components/OnboardingPage/types";

import Api from "./index";

interface UserLoginPayload {
  id: number;
  email: string;
}

interface AadhaarOtpVerifyPayload {
  aadharNo: string;
  otp: string;
  referenceId: string;
}

export const getLoginPageDetails = (slug: string) =>
  Api.get(`/candidate/details/${slug}`);

export const userLogin = (payload: UserLoginPayload) =>
  Api.post("/candidate/login", payload);

export const candidateNotJoining = (candidateId: number) =>
  Api.post("/candidate/reject", {
    id: candidateId,
  });

export const uploadFile = (file: File) => {
  const formData = new FormData();
  formData.set("file", file);
  return Api.postMultipart("/common/upload", formData);
};

export const updateCandidateBasicDetails = (payload: BasicDetailsData) =>
  Api.put("/candidate", { meta: payload });

export const updateCandidateNid = (payload: NidDetails) =>
  Api.put("/candidate", { nid: payload });

export const updateCandidateAddress = (payload: AddressDetails) =>
  Api.put("/candidate", { address: payload });

export const updateCandidateEducation = (payload: EducationDetails[]) =>
  Api.put("/candidate", { education: payload });

export const updateCandidateEmployment = (
  payload: { data: EmployerDetails[] } | { isFresher: boolean },
) => Api.put("/candidate", { employment: payload, verify: true });

export const sendAadhaarOtp = (aadharNo: string) =>
  Api.post("/candidate/aadhar/otp", { aadharNo });

export const verifyAadhaarOtp = (payload: AadhaarOtpVerifyPayload) =>
  Api.post("/candidate/aadhar/verify", payload);
