import { SelectHTMLAttributes } from "react";

import styles from "./index.module.scss";
import classnames from "classnames";

export interface SelectOptions {
  value: string;
  name: string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: SelectOptions[];
  onOptionSelect: (val: string) => void;
  label?: string;
  labelClassName?: string;
}

const Select = (props: SelectProps) => (
  <div className={props.className}>
    {props.label && (
      <div className={classnames(styles["label"], props.labelClassName)}>
        {props.label}
      </div>
    )}
    <select
      {...props}
      className={styles["select"]}
      onChange={(ev) => props.onOptionSelect(ev.target.value)}
      value={props.value}
    >
      <option value="" selected disabled hidden>
        Choose here
      </option>
      {props.options.map((val) => (
        <option key={val.value} value={val.value}>
          {val.name}
        </option>
      ))}
    </select>
  </div>
);

export default Select;
