import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getLoginPageDetails } from "apis/serverRoutes";
import Loader from "components/molecules/Loader";

import Banner from "./Banner";
import Form from "./Form";
import styles from "./index.module.scss";
import { LoginData, UserStatus } from "./types";
import { RejectedIcon, SuccessIcon } from "./icons";

const LoginPage = () => {
  const { path } = useParams();

  const [loginData, setLoginData] = useState<LoginData | null>(null);

  useEffect(() => {
    getLoginDetails();
  }, []);

  const getLoginDetails = () => {
    if (!path) return;
    setLoginData(null);
    getLoginPageDetails(path)
      .then((res) => {
        if (res.data) setLoginData(res.data);
        else console.error("Something went wring!!");
      })
      .catch((err) => console.error("Something went wring!!", err));
  };

  return (
    <div className={styles["container"]}>
      {loginData === null ? (
        <Loader loaderSize={32} color={"#463cff"} />
      ) : (
        <>
          <Banner loginData={loginData} className={styles["banner"]} />
          {loginData.status === UserStatus.Rejected ? (
            <div className={styles["status-holder"]}>
              <div className={styles["status-name"]}>Hey, {loginData.name}</div>
              <RejectedIcon className={styles["status-icon"]} />
              <div className={styles["status-text"]}>
                You have expressed your decision not to join the organization.
              </div>
            </div>
          ) : loginData.status === UserStatus.Submitted ? (
            <div className={styles["status-holder"]}>
              <div className={styles["status-name"]}>Hey, {loginData.name}</div>
              <SuccessIcon className={styles["status-icon"]} />
              <div className={styles["status-text"]}>
                You have successfully completed the submission of the
                documentation form.
              </div>
            </div>
          ) : (
            <Form
              loginData={loginData}
              className={styles["form"]}
              getLoginDetails={getLoginDetails}
            />
          )}
        </>
      )}
    </div>
  );
};

export default LoginPage;
