import Input from "components/molecules/Input";
import DateInput from "components/molecules/DateInput";

import styles from "./index.module.scss";
import { AddressDetails } from "../types";
import PhotoUploader from "../PhotoUploader";

interface AddressProps {
  addressDetails: AddressDetails;
  setAddressDetails: (val: AddressDetails) => void;
}

const Address = ({ setAddressDetails, addressDetails }: AddressProps) => (
  <>
    <div className={styles["radio-holder"]}>
      <div className={styles["label"]}>Is this is address in India?</div>
      <Input
        type={"radio"}
        label={"Yes"}
        checked={addressDetails.inIndia}
        className={styles["radio"]}
        labelClassName={styles["radio-label"]}
        onClick={() => setAddressDetails({ ...addressDetails, inIndia: true })}
      />
      <Input
        type={"radio"}
        label={"No"}
        checked={!addressDetails.inIndia}
        className={styles["radio"]}
        labelClassName={styles["radio-label"]}
        onClick={() => setAddressDetails({ ...addressDetails, inIndia: false })}
      />
    </div>
    <div className={styles["input-container"]}>
      <Input
        className={styles["input"]}
        label={"Flat/ House No/ Floor/ Building No"}
        value={addressDetails.houseNo}
        onChange={(ev) =>
          setAddressDetails({ ...addressDetails, houseNo: ev.target.value })
        }
        placeholder={"Enter House Number"}
      />
      <Input
        className={styles["input"]}
        label={"Colony/ Street/ Locality"}
        value={addressDetails.locality}
        onChange={(ev) =>
          setAddressDetails({ ...addressDetails, locality: ev.target.value })
        }
        placeholder={"Enter Locality"}
      />
      <Input
        className={styles["input"]}
        label={"Residency name"}
        value={addressDetails.residencyName}
        onChange={(ev) =>
          setAddressDetails({
            ...addressDetails,
            residencyName: ev.target.value,
          })
        }
        placeholder={"Enter Residency name"}
      />
    </div>
    <div className={styles["input-container"]}>
      <Input
        className={styles["input"]}
        label={"City"}
        value={addressDetails.city}
        onChange={(ev) =>
          setAddressDetails({ ...addressDetails, city: ev.target.value })
        }
        placeholder={"Enter City"}
      />
      <Input
        className={styles["input"]}
        label={"State"}
        value={addressDetails.state}
        onChange={(ev) =>
          setAddressDetails({ ...addressDetails, state: ev.target.value })
        }
        placeholder={"Enter State"}
      />
      <Input
        className={styles["input"]}
        label={"Pin code"}
        value={addressDetails.pincode}
        onChange={(ev) =>
          setAddressDetails({
            ...addressDetails,
            pincode: ev.target.value,
          })
        }
        placeholder={"Enter Pin Code"}
      />
    </div>
    <div className={styles["input-container"]}>
      <Input
        className={styles["input"]}
        label={"Landmark"}
        value={addressDetails.landmark}
        onChange={(ev) =>
          setAddressDetails({ ...addressDetails, landmark: ev.target.value })
        }
        placeholder={"Enter Landmark"}
      />
      <DateInput
        label={"Residing from"}
        selectedDate={addressDetails.residingFrom}
        onSelectedDateChange={(date) =>
          setAddressDetails({
            ...addressDetails,
            residingFrom: date,
          })
        }
        containerClassName={styles["input"]}
        placeholderText={"Enter Residing from date"}
      />
    </div>
    <div className={styles["uploader-holder"]}>
      <div className={styles["label"]}>Upload Address Proof</div>
      <PhotoUploader
        className={styles["uploader"]}
        image={addressDetails.residencyProof}
        onImageChange={(residencyProof) =>
          setAddressDetails({ ...addressDetails, residencyProof })
        }
      />
    </div>
  </>
);

export default Address;
