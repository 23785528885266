import { BrowserRouter } from "react-router-dom";

import Main from "components/Main";

import ClientRoutes from "./ClientRoutes";

const App = () => (
  <BrowserRouter>
    <Main>
      <ClientRoutes />
    </Main>
  </BrowserRouter>
);

export default App;
