import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserDataModel } from "utils/types";
import { removeUserDataLs, setUserDataLs } from "utils/localstorage";

export interface AuthStateModel {
  userData?: UserDataModel;
  loginSlug: string;
}

const initialState: AuthStateModel = {
  userData: undefined,
  loginSlug: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserDataModel>) => {
      setUserDataLs(action.payload);
      state.userData = action.payload;
    },
    resetAuth: (state, action: PayloadAction<string>) => {
      removeUserDataLs();
      state.loginSlug = action.payload;
      state.userData = null;
    },
  },
});

export const { setUserData, resetAuth } = authSlice.actions;

export default authSlice.reducer;
