import { useState } from "react";
import { useSelector } from "react-redux";

import {
  updateCandidateAddress,
  updateCandidateBasicDetails,
  updateCandidateEducation,
  updateCandidateEmployment,
  updateCandidateNid,
} from "apis/serverRoutes";
import Button, { ButtonType } from "components/molecules/Button";
import { ReduxStateModel } from "utils/redux/store";
import { onLogoutSuccess } from "utils/auth";

import Address from "./Address";
import BasicDetails from "./BasicDetails";
import styles from "./index.module.scss";
import {
  AddressDetails,
  BasicDetailsData,
  EducationDetails,
  EmployerDetails,
  FormStatus,
  FormStep,
  INITIAL_ADDRESS_DETAILS,
  INITIAL_BASIC_DETAILS,
  INITIAL_EDUCATION_DETAILS,
  INITIAL_EMPLOYER_DETAILS,
  INITIAL_FORM_STATUS,
  INITIAL_NID_DETAILS,
  NidDetails,
} from "./types";
import Nid from "./Nid";
import Education from "./Education";
import Employer from "./Employer";
import { CompletedIcon } from "./icons";

const OnboardingPage = () => {
  const userData = useSelector((state: ReduxStateModel) => state.auth.userData);
  const initialBasicDetails: BasicDetailsData = {
    ...INITIAL_BASIC_DETAILS,
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
  };

  const [formStatus, setFormStatus] = useState<FormStatus>(INITIAL_FORM_STATUS);
  const [formStep, setFormStep] = useState<FormStep>(FormStep.Basic);
  const [basicDetails, setBasicDetails] =
    useState<BasicDetailsData>(initialBasicDetails);
  const [nidDetails, setNidDetails] = useState<NidDetails>(INITIAL_NID_DETAILS);
  const [addressDetails, setAddressDetails] = useState<AddressDetails>(
    INITIAL_ADDRESS_DETAILS,
  );
  const [educationDetails, setEducationDetails] = useState<EducationDetails[]>([
    { ...INITIAL_EDUCATION_DETAILS },
  ]);
  const [isFresher, setIsFresher] = useState<boolean>(false);
  const [employerDetails, setEmployerDetails] = useState<EmployerDetails[]>([
    { ...INITIAL_EMPLOYER_DETAILS },
  ]);
  const [nextLoading, setNextLoading] = useState<boolean>(false);

  const onNextClick = () => {
    setNextLoading(true);
    if (formStep === FormStep.Basic)
      updateCandidateBasicDetails(basicDetails)
        .then((res) => {
          if (res.data) {
            setFormStep(FormStep.Nid);
            setFormStatus({ ...formStatus, [FormStep.Basic]: true });
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setNextLoading(false));
    else if (formStep === FormStep.Nid)
      updateCandidateNid(nidDetails)
        .then((res) => {
          if (res.data) {
            setFormStep(FormStep.Address);
            setFormStatus({ ...formStatus, [FormStep.Nid]: true });
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setNextLoading(false));
    else if (formStep === FormStep.Address)
      updateCandidateAddress(addressDetails)
        .then((res) => {
          if (res.data) {
            setFormStep(FormStep.Education);
            setFormStatus({ ...formStatus, [FormStep.Address]: true });
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setNextLoading(false));
    else if (formStep === FormStep.Education)
      updateCandidateEducation(educationDetails)
        .then((res) => {
          if (res.data) {
            setFormStep(FormStep.Employer);
            setFormStatus({ ...formStatus, [FormStep.Education]: true });
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setNextLoading(false));
    else if (formStep === FormStep.Employer)
      updateCandidateEmployment(
        isFresher
          ? {
              isFresher,
            }
          : { data: employerDetails },
      )
        .then((res) => {
          if (res.data.slug) {
            setFormStatus({ ...formStatus, [FormStep.Employer]: true });
            onLogoutSuccess(res.data.slug);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setNextLoading(false));
  };

  const isNextDisabled = (): boolean => {
    let disabled = false;
    if (formStep === FormStep.Basic)
      Object.entries(basicDetails).forEach(([, value]) => {
        if (!value) disabled = true;
      });
    else if (formStep === FormStep.Nid)
      Object.entries(nidDetails).forEach(([, value]) => {
        if (!value) disabled = true;
      });
    else if (formStep === FormStep.Address)
      Object.entries(addressDetails).forEach(([, value]) => {
        if (value === "") disabled = true;
      });
    else if (formStep === FormStep.Education)
      educationDetails.forEach((education) =>
        Object.entries(education).forEach(([, value]) => {
          if (!value) disabled = true;
        }),
      );
    else if (formStep === FormStep.Employer) {
      if (!isFresher) {
        Object.entries(formStatus).forEach(([key, value]) => {
          if (key !== FormStep.Employer && !value) disabled = true;
        });
        employerDetails.forEach((employer) =>
          Object.entries(employer).forEach(([, value]) => {
            if (typeof value === "object")
              Object.entries(value).forEach(([, value]) => {
                if (!value) disabled = true;
              });
            else if (value === "" || value === 0) disabled = true;
          }),
        );
      }
    }
    return disabled;
  };

  const onResetClick = () => {
    if (formStep === FormStep.Basic) setBasicDetails(initialBasicDetails);
    else if (formStep === FormStep.Nid) setNidDetails(INITIAL_NID_DETAILS);
    else if (formStep === FormStep.Address)
      setAddressDetails(INITIAL_ADDRESS_DETAILS);
    else if (formStep === FormStep.Education)
      setEducationDetails([INITIAL_EDUCATION_DETAILS]);
    else if (formStep === FormStep.Employer)
      setEmployerDetails([INITIAL_EMPLOYER_DETAILS]);
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["company-holder"]}>
        <div className={styles["company-name"]}>{userData?.company.name}</div>
        <div className={styles["company-description"]}>
          keugvh iueiu evh uievh iuehv oiehvio ehv oievh oiehv oivh eoihei ovhve
          oiehv oievh oievh oiveh oiveh oiveh oi
        </div>
      </div>
      <div className={styles["form-holder"]}>
        <div className={styles["header"]}>
          <div className={styles["name"]}>
            {userData?.firstName + " " + userData?.lastName}
          </div>
          <div className={styles["cta-holder"]}>
            <Button
              className={styles["cta"]}
              buttonType={ButtonType.Outlined}
              onClick={onResetClick}
            >
              Reset
            </Button>
            <Button
              className={styles["cta"]}
              onClick={onNextClick}
              disabled={isNextDisabled()}
              loading={nextLoading}
            >
              {formStep === FormStep.Employer ? "Submit" : "Next"}
            </Button>
          </div>
        </div>
        <div className={styles["tab-container"]}>
          <div className={styles["tabs-holder"]}>
            {Object.entries(FormStep).map(([key, value]) => (
              <div
                key={key}
                onClick={() => {
                  if (formStatus[value]) setFormStep(value);
                }}
                className={
                  styles[
                    formStep === value
                      ? "selected-tab"
                      : formStatus[value]
                        ? "completed-tab"
                        : "tab"
                  ]
                }
              >
                <div>{value}</div>
                {formStatus[value] && (
                  <CompletedIcon className={styles["completed-icon"]} />
                )}
              </div>
            ))}
          </div>
          <div className={styles["tab-content"]}>
            {formStep === FormStep.Basic ? (
              <BasicDetails
                basicDetails={basicDetails}
                setBasicDetails={setBasicDetails}
              />
            ) : formStep === FormStep.Nid ? (
              <Nid nidDetails={nidDetails} setNidDetails={setNidDetails} />
            ) : formStep === FormStep.Address ? (
              <Address
                addressDetails={addressDetails}
                setAddressDetails={setAddressDetails}
              />
            ) : formStep === FormStep.Education ? (
              <Education
                educationDetails={educationDetails}
                setEducationDetails={setEducationDetails}
              />
            ) : (
              <Employer
                employerDetails={employerDetails}
                setEmployerDetails={setEmployerDetails}
                setIsFresher={setIsFresher}
                isFresher={isFresher}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingPage;
