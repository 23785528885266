import { ReactNode } from "react";
import { FileUploader as FileUploaderWidget } from "react-drag-drop-files";

interface FileUploaderProps {
  children?: ReactNode;
  fileTypes: string[];
  handleChange: (file: File) => void;
  hoverTitle?: string;
  className?: string;
  onTypeError?: () => void;
  fileOrFiles?: null | File | File[];
  disabled?: boolean;
}

const FileUploader = ({
  fileTypes,
  handleChange,
  children,
  hoverTitle = "Drop your files here",
  className,
  onTypeError,
  fileOrFiles,
  disabled,
}: FileUploaderProps) => (
  <FileUploaderWidget
    handleChange={handleChange}
    name="file"
    types={fileTypes}
    label={hoverTitle}
    classes={className}
    onTypeError={onTypeError}
    fileOrFiles={fileOrFiles}
    disabled={disabled}
  >
    {children}
  </FileUploaderWidget>
);

export default FileUploader;
