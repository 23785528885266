import { InputHTMLAttributes } from "react";
import classnames from "classnames";

import styles from "./index.module.scss";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label?: string;
  labelClassName?: string;
}

const Input = ({ className, labelClassName, label, ...props }: InputProps) => (
  <div className={className}>
    {label && (
      <div className={classnames(styles["label"], labelClassName)}>{label}</div>
    )}
    <input className={styles["input"]} {...props} />
  </div>
);

export default Input;
