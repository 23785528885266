import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import store from "utils/redux/store";
import { onLogoutSuccess } from "utils/auth";
import { API_BASE_URL } from "utils/constants";
import { postFormData } from "utils/network";

const getAuthHeaders = (): Record<string, any> => {
  const userData = store.getState().auth.userData;
  if (!userData) return {};
  return {
    "auth-token": userData.accessToken,
    "auth-user-id": userData.id,
    "company-id": userData.company.id,
  };
};

export default class Api {
  static client = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000,
    headers: {
      Accept: "application/json",
    },
  });
  static temp = Api.client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) onLogoutSuccess();
      return error;
    },
  );
  static get = (
    path: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      Api.client
        .get(path, {
          ...config,
          headers: getAuthHeaders(),
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  static post = (
    path: string,
    payload: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      Api.client
        .post(path, payload, {
          headers: getAuthHeaders(),
          ...config,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  static put = (path: string, payload?: any): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      Api.client
        .put(path, payload, {
          headers: getAuthHeaders(),
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  static delete = (path: string, payload?: any): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      Api.client
        .delete(path, {
          data: payload,
          headers: getAuthHeaders(),
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  static postMultipart = (path: string, payload: any) => {
    return postFormData(`${API_BASE_URL}` + path, payload, getAuthHeaders());
  };
}
