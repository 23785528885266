import DatePicker, { DatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getDateObject, getDateString } from "utils/date";

import styles from "./index.module.scss";

type CustomDatePickerProps = DatePickerProps & {
  selectedDate: string;
  onSelectedDateChange: (date: string) => void;
  containerClassName?: string;
  label: string;
};

const DateInput = ({
  selectedDate,
  onSelectedDateChange,
  containerClassName,
  label,
  ...props
}: CustomDatePickerProps) => (
  <div className={containerClassName}>
    <div className={styles["label"]}>{label}</div>
    <DatePicker
      placeholderText={props.placeholderText}
      selected={getDateObject(selectedDate)}
      onChange={(date) => onSelectedDateChange(getDateString(date))}
      className={styles["date-picker"]}
      wrapperClassName={styles["date-picker-wrapper"]}
      adjustDateOnChange
      showYearDropdown
      showMonthDropdown
      dropdownMode={"select"}
    />
  </div>
);

export default DateInput;
