import store from "utils/redux/store";
import { resetAuth, setUserData } from "utils/redux/slice/auth";
import { UserDataModel } from "utils/types";

export const onLoginSuccess = (userData: UserDataModel) => {
  store.dispatch(setUserData(userData));
};
export const onLogoutSuccess = (loginSlug: string = "") => {
  store.dispatch(resetAuth(loginSlug));
};
