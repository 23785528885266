export enum UserStatus {
  Submitted = "SUBMITTED",
  Rejected = "REJECTED",
  Pending = "PENDING",
  Requested = "REQUESTED",
}

export interface LoginData {
  companyDescription: string;
  companyName: string;
  id: number;
  message: string;
  name: string;
  status: UserStatus;
}
