import Button from "components/molecules/Button";
import Input from "components/molecules/Input";
import DateInput from "components/molecules/DateInput";

import styles from "./index.module.scss";
import { EmployerDetails, INITIAL_EMPLOYER_DETAILS } from "../types";

const Employer = ({
  employerDetails,
  setEmployerDetails,
  setIsFresher,
  isFresher,
}: {
  employerDetails: EmployerDetails[];
  setEmployerDetails: (details: EmployerDetails[]) => void;
  setIsFresher: (isFresher: boolean) => void;
  isFresher: boolean;
}) => (
  <>
    <div className={styles["radio-holder"]}>
      <div className={styles["label"]}>Are you a fresher?</div>
      <Input
        type={"radio"}
        label={"Yes"}
        checked={isFresher}
        className={styles["radio"]}
        labelClassName={styles["radio-label"]}
        onClick={() => setIsFresher(true)}
      />
      <Input
        type={"radio"}
        label={"No"}
        checked={!isFresher}
        className={styles["radio"]}
        labelClassName={styles["radio-label"]}
        onClick={() => setIsFresher(false)}
      />
    </div>
    {!isFresher && (
      <>
        {employerDetails.map((employer, idx) => (
          <>
            {idx !== 0 && (
              <Button
                className={styles["remove-cta"]}
                onClick={() =>
                  setEmployerDetails([
                    ...employerDetails.slice(0, idx),
                    ...employerDetails.slice(idx + 1, employerDetails.length),
                  ])
                }
              >
                - Remove
              </Button>
            )}
            <div className={styles["input-container"]}>
              <Input
                className={styles["input"]}
                label={"Company name"}
                value={employer.company}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].company = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Company name"}
              />
              <Input
                className={styles["input"]}
                label={"Designation"}
                value={employer.designation}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].designation = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Designation"}
              />
              <Input
                className={styles["input"]}
                label={"City and Location"}
                value={employer.city}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].city = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter City and Location"}
              />
            </div>
            <div className={styles["input-container"]}>
              <Input
                className={styles["input"]}
                label={"Contact Number"}
                value={employer.phone}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].phone = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Contact Number"}
              />
              <Input
                className={styles["input"]}
                label={"Company Email"}
                value={employer.email}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].email = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Company Email"}
              />
              <Input
                className={styles["input"]}
                label={"Complete Address"}
                value={employer.address}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].address = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Complete Address"}
              />
            </div>
            <div className={styles["input-container-small"]}>
              <Input
                className={styles["input"]}
                label={"Employee Type"}
                value={employer.employeeType}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].employeeType = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Employee Type"}
              />
              <Input
                className={styles["input"]}
                label={"Department"}
                value={employer.department}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].department = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Department"}
              />
            </div>
            <div className={styles["radio-holder"]}>
              <div className={styles["label"]}>Currently working here?</div>
              <Input
                type={"radio"}
                label={"Yes"}
                checked={employer.currentlyWorking}
                className={styles["radio"]}
                labelClassName={styles["radio-label"]}
                onClick={() => {
                  const educationList = [...employerDetails];
                  educationList[idx].currentlyWorking = true;
                  delete educationList[idx].endsAt;
                  setEmployerDetails(educationList);
                }}
              />
              <Input
                type={"radio"}
                label={"No"}
                checked={!employer.currentlyWorking}
                className={styles["radio"]}
                labelClassName={styles["radio-label"]}
                onClick={() => {
                  const educationList = [...employerDetails];
                  educationList[idx].currentlyWorking = false;
                  educationList[idx].endsAt = "";
                  setEmployerDetails(educationList);
                }}
              />
            </div>
            <div className={styles["input-container-small"]}>
              <DateInput
                label={"Starting Date"}
                selectedDate={employer.startsFrom}
                onSelectedDateChange={(date) => {
                  const educationList = [...employerDetails];
                  educationList[idx].startsFrom = date;
                  setEmployerDetails(educationList);
                }}
                containerClassName={styles["input"]}
                placeholderText={"Enter Starting Date"}
              />
              {employer.endsAt !== undefined && (
                <DateInput
                  label={"Ending Date"}
                  selectedDate={employer.endsAt}
                  onSelectedDateChange={(date) => {
                    const educationList = [...employerDetails];
                    educationList[idx].endsAt = date;
                    setEmployerDetails(educationList);
                  }}
                  containerClassName={styles["input"]}
                  placeholderText={"Enter Ending Date"}
                />
              )}
            </div>
            <div className={styles["title"]}>Reporting Manager Details</div>
            <div className={styles["input-container"]}>
              <Input
                className={styles["input"]}
                label={"First Name"}
                value={employer.manager.firstName}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].manager.firstName = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter First Name"}
              />
              <Input
                className={styles["input"]}
                label={"Middle name"}
                value={employer.manager.middleName}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].manager.middleName = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Middle Name"}
              />
              <Input
                className={styles["input"]}
                label={"Last Name"}
                value={employer.manager.lastName}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].manager.lastName = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Last Name"}
              />
            </div>
            <div className={styles["input-container"]}>
              <Input
                className={styles["input"]}
                label={"Phone Number"}
                value={employer.manager.phone}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].manager.phone = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Phone Number"}
              />
              <Input
                className={styles["input"]}
                label={"Email"}
                value={employer.manager.email}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].manager.email = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Email"}
              />
              <Input
                className={styles["input"]}
                label={"Department"}
                value={employer.manager.department}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].manager.department = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Department"}
              />
            </div>
            <div className={styles["input-container"]}>
              <Input
                className={styles["input"]}
                label={"Manager Employee Code"}
                value={employer.manager.employeeCode}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].manager.employeeCode = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Manager's Employee Code"}
              />
            </div>
            <div className={styles["title"]}>Salary Details</div>
            <div className={styles["input-container"]}>
              <Input
                className={styles["input"]}
                label={"Salary (LPA)"}
                value={employer.salary}
                type={"number"}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].salary = parseInt(ev.target.value);
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Salary in LPA"}
              />
              <Input
                className={styles["input"]}
                label={"UAN number"}
                value={employer.uan}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].uan = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter UAN number"}
              />
              <Input
                className={styles["input"]}
                label={"Employee Code"}
                value={employer.employeeCode}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].employeeCode = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Employee Code"}
              />
            </div>
            <div className={styles["input-container-small"]}>
              <Input
                className={styles["input"]}
                label={"Grade / Band"}
                value={employer.band}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].band = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Grade/Band"}
              />
              <Input
                className={styles["input"]}
                label={"Remark"}
                value={employer.remark}
                onChange={(ev) => {
                  const educationList = [...employerDetails];
                  educationList[idx].remark = ev.target.value;
                  setEmployerDetails(educationList);
                }}
                placeholder={"Enter Remark"}
              />
            </div>
          </>
        ))}
        <Button
          className={styles["add-education-cta"]}
          onClick={() => {
            setEmployerDetails([
              ...employerDetails,
              { ...INITIAL_EMPLOYER_DETAILS },
            ]);
          }}
        >
          + Add Employer
        </Button>
      </>
    )}
  </>
);

export default Employer;
