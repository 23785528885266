import classnames from "classnames";
import { useState } from "react";

import { uploadFile } from "apis/serverRoutes";
import ImageModal from "components/molecules/ImageModal";
import FileUploader from "components/molecules/FileUploader";
import Button, { ButtonType } from "components/molecules/Button";

import { DeleteIcon, UploadIcon } from "./icons";
import styles from "./index.module.scss";
import Loader from "../../molecules/Loader";

const SUPPORTED_IMAGE_TYPE = ["JPG", "JPEG", "PNG", "GIF"];

interface PhotoUploader {
  onImageChange: (url: string) => void;
  title?: string;
  image: string;
  className?: string;
}

const PhotoUploader = ({
  onImageChange,
  title = "Drag your file(s) to start uploading",
  image,
  className,
}: PhotoUploader) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [modalImage, setModalImage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);

  return (
    <div className={classnames(styles["uploaded-holder"], className)}>
      <ImageModal onClose={() => setModalImage("")} image={modalImage} />
      {image ? (
        <>
          <img
            src={image}
            className={styles["uploaded-image"]}
            alt={"uploaded image"}
            onClick={() => setModalImage(image)}
          />
          <DeleteIcon
            className={styles["delete-photo"]}
            onClick={() => onImageChange("")}
          />
        </>
      ) : (
        <FileUploader
          handleChange={(file) => {
            setShowError(false);
            setLoader(true);
            uploadFile(file)
              .then((res) => {
                if (res?.url) onImageChange(res.url);
              })
              .catch((err) => console.error(err))
              .finally(() => setLoader(false));
          }}
          onTypeError={() => setShowError(true)}
          className={styles["uploader-holder"]}
          fileTypes={SUPPORTED_IMAGE_TYPE}
          disabled={loader}
        >
          {loader ? (
            <Loader loaderSize={32} color={"#463cff"} />
          ) : (
            <>
              <UploadIcon />
              <div className={styles["uploader-text"]}>{title}</div>
              <div className={styles["or-holder"]}>
                <div className={styles["line"]} />
                <div className={styles["or-text"]}>OR</div>
                <div className={styles["line"]} />
              </div>
              <Button buttonType={ButtonType.Outlined}>Browse Files</Button>
              {showError && (
                <div className={styles["upload-error"]}>
                  Image should be in {SUPPORTED_IMAGE_TYPE.join(", ")} format.
                </div>
              )}
            </>
          )}
        </FileUploader>
      )}
    </div>
  );
};

export default PhotoUploader;
