import classnames from "classnames";

import styles from "./index.module.scss";
import { BottomRightIcon, TopLeftIcon } from "./icons";
import { LoginData } from "../types";

const Banner = ({
  className,
  loginData,
}: {
  className: string;
  loginData: LoginData;
}) => (
  <div className={classnames(styles["container"], className)}>
    <TopLeftIcon className={styles["top-left-icon"]} />
    <BottomRightIcon className={styles["bottom-right-icon"]} />
    <div className={styles["title"]}>{loginData.companyName}</div>
    <div className={styles["subtitle"]}>
      {loginData.companyDescription ||
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}
    </div>
  </div>
);

export default Banner;
