export const postFormData = async (
  url: string,
  payload = new FormData(),
  headers: Record<string, any> = {},
  controller = new AbortController(),
) => {
  try {
    let header = new Headers();
    for (const key in headers) {
      header.append(key, headers[key]);
    }
    const response = await fetch(url, {
      method: "POST",
      headers: header,
      body: payload,
      signal: controller.signal,
    });
    const responseHeaders = await response.headers;
    if (response.status === 200) {
      if (
        responseHeaders.get("content-type")?.indexOf("application/json") !== -1
      ) {
        return await response.json();
      } else {
        return await response.text();
      }
    } else {
      let data = {};
      try {
        data = await response.json();
        return {
          statusCode: response.status,
          error: "Something went wrong",
          ...data,
        };
      } catch (err) {
        return {
          statusCode: response.status,
          error: "Something went wrong",
        };
      }
    }
  } catch (error) {
    console.error("network error in fetch call", error);
    return {
      error: "Network Error",
    };
  }
};
