import { Route, Routes } from "react-router-dom";

import LoginPage from "components/LoginPage";
import OnboardingPage from "components/OnboardingPage";
import NotFound from "components/NotFound";

import { ROUTES } from "./utils/routes";

const ClientRoutes = () => (
  <Routes>
    <Route path={ROUTES.login.path} element={<LoginPage />} />
    <Route path={ROUTES.dashboard.path} element={<OnboardingPage />} />
    <Route path={"*"} element={<NotFound />} />
  </Routes>
);

export default ClientRoutes;
