export const DYNAMIC_PATH = ":path";

export const getLoginPath = (loginSlug: string) => `/login/${loginSlug}`;

export const ROUTES = {
  dashboard: {
    path: "/onboarding",
    auth: true,
  },
  login: {
    path: getLoginPath(DYNAMIC_PATH),
    auth: false,
  },
};
